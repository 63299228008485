<template>
  <div>
    <div class="d-flex justify-content-between align-items-center mb-3">
      <h1 class="headingBase">Performance Overview</h1>

      <div class="d-flex">

        <b-button variant="primary" id="basicFilter" class="mb-0 mx-1 primaryBTN" v-b-toggle.collapse-1>
          <b-icon icon="funnel"></b-icon> Filter
        </b-button>
      </div>
    </div>


    <div>
      <b-collapse id="collapse-1" class="my-4">
        <b-card class="filterBox mb-0">
          <b-row>
            <b-col md="6" xl="3" class="my-2 px-1">
              <b-form-group label="Branch ID:" label-for="input-formatter" label-class="text-left text-label">
                <b-form-select v-model="selected1" :options="options1" class="form-control"></b-form-select>
              </b-form-group>
            </b-col>

            <b-col md="6" xl="3" class="my-2 px-1">
              <b-form-group label="Customer Name:" label-for="input-formatter" label-class="text-left text-label">
                <b-form-select v-model="selected2" :options="options2" class="form-control"></b-form-select>
              </b-form-group>
            </b-col>


            <b-col md="6" xl="3" class="my-2 px-1">
              <b-form-group label="Month Year:" label-for="input-formatter" label-class="text-left text-label">
                <b-form-select v-model="selected3" :options="options3" class="form-control"></b-form-select>
              </b-form-group>
            </b-col>

          </b-row>

          <b-row>
            <b-col md="6" xl="3" class="my-2 px-1">
              <b-button class="mb-0 ml-1 primaryBTN w-100">
                Search
              </b-button>
            </b-col>
            <b-col md="6" xl="3" class="my-2 px-1">
              <b-button v-b-toggle.collapse-1 class="mb-0 ml-1 primaryBTN w-100">
                Reset
              </b-button>
            </b-col>
          </b-row>

        </b-card>
      </b-collapse>
    </div>

    <section id="minimal-statistics">
      <div class="row my-2">
        <div class="col-xl-3 col-sm-6 col-12">
          <div class="row my-2">
            <div class="col-xl-12 col-sm-12 col-12">
              <div class="card card-1">
                <div class="card-content">
                  <div class="py-1 text-center">
                    <div class="media ">
                      <div class="media-body text-center">
                        <span>KPI</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-xl-12 col-sm-12 col-12">
              <div class="card card-1">
                <div class="card-content">
                  <div class="card-body text-center">
                    <div class="media ">
                      <div class="media-body text-center">
                        <h3><b-icon icon="arrow-down" font-scale="0.8" variant="danger"></b-icon> 10% </h3>
                        <span>Bookings</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-xl-12 col-sm-12 col-12">
              <div class="card card-2">
                <div class="card-content">
                  <div class="card-body text-center">
                    <div class="media">
                      <div class="media-body text-right ">
                        <h3><b-icon icon="arrow-down" font-scale="0.8" variant="danger"></b-icon>12% </h3>
                        <span>Total Sales</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-xl-12 col-sm-12 col-12">
              <div class="card card-3">
                <div class="card-content">
                  <div class="card-body text-center">
                    <div class="media">
                      <div class="media-body text-right ">
                        <h3><b-icon icon="arrow-down" font-scale="0.8" variant="danger"></b-icon>17% </h3>
                        <span>Average L2B</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-xl-12 col-sm-12 col-12">
              <div class="card card-1">
                <div class="card-content">
                  <div class="card-body text-center">
                    <div class="media">
                      <div class="media-body text-right ">
                        <h3><b-icon icon="arrow-down" font-scale="0.8" variant="danger"></b-icon> 10% </h3>
                        <span>Bookings</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-xl-12 col-sm-12 col-12">
              <div class="card card-2">
                <div class="card-content">
                  <div class="card-body text-center">
                    <div class="media">
                      <div class="media-body text-right ">
                        <h3><b-icon icon="arrow-down" font-scale="0.8" variant="danger"></b-icon>12% </h3>
                        <span>Total Sales</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-xl-12 col-sm-12 col-12">
              <div class="card card-2">
                <div class="card-content">
                  <div class="card-body text-center">
                    <div class="media">
                      <div class="media-body text-right ">
                        <h3><b-icon icon="arrow-down" font-scale="0.8" variant="danger"></b-icon>12% </h3>
                        <span>Total Sales</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-xl-12 col-sm-12 col-12">
              <div class="card card-3">
                <div class="card-content">
                  <div class="card-body text-center">
                    <div class="media">
                      <div class="media-body text-right ">
                        <h3><b-icon icon="arrow-down" font-scale="0.8" variant="danger"></b-icon>17% </h3>
                        <span>Average L2B</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-xl-12 col-sm-12 col-12">
              <div class="card card-1">
                <div class="card-content">
                  <div class="card-body text-center">
                    <div class="media">
                      <div class="media-body text-right ">
                        <h3><b-icon icon="arrow-down" font-scale="0.8" variant="danger"></b-icon> 10% </h3>
                        <span>Bookings</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-xl-12 col-sm-12 col-12">
              <div class="card card-2">
                <div class="card-content">
                  <div class="card-body text-center">
                    <div class="media">
                      <div class="media-body text-right ">
                        <h3><b-icon icon="arrow-down" font-scale="0.8" variant="danger"></b-icon>12% </h3>
                        <span>Total Sales</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-xl-12 col-sm-12 col-12">
              <div class="card card-3">
                <div class="card-content">
                  <div class="card-body text-center">
                    <div class="media">
                      <div class="media-body text-right ">
                        <h3><b-icon icon="arrow-down" font-scale="0.8" variant="danger"></b-icon>17% </h3>
                        <span>Average L2B</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>




          </div>
        </div>
        <div class="col-xl-4 col-sm-6 col-12">
          <div class="row my-2">

            <div class="col-xl-12 col-sm-6 col-12">
              <div class="card card-2 graphCard">
                <div class="card-content">
                  <div class="card-body">
                    <div class="d-flex align-items-center position-relative mb-1">
                      <b-icon icon="arrows-expand" class="GraphIcon" font-scale="1"></b-icon>
                      <p class="GraphLabel">Overall percentage share of sales across destination market</p>
                    </div>
                    <ApexChart type="pie" :options="piechartOptions" :series="piechartSeries"></ApexChart>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-xl-12 col-sm-6 col-12">
              <div class="card card-3 graphCard">
                <div class="card-content">
                  <div class="card-body">
                    <div class="d-flex align-items-center position-relative mb-1">
                      <b-icon icon="arrows-expand" class="GraphIcon" font-scale="1"></b-icon>
                      <p class="GraphLabel">Percentage share of sales across SM-Destination corridor</p>
                    </div>
                    <ApexChart type="donut" :options="corridorpiechartOptions" :series="corridorpiechartSeries"></ApexChart>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-xl-12 col-sm-6 col-12">
              <div class="card card-2 graphCard">
                <div class="card-content">
                  <div class="card-body">
                    <div class="d-flex align-items-center position-relative mb-1">
                      <b-icon icon="arrows-expand" class="GraphIcon" font-scale="1"></b-icon>
                      <p class="GraphLabel">Overall percentage share of sales across destination market</p>
                    </div>
                    <ApexChart type="pie" :options="piechartOptions" :series="piechartSeries"></ApexChart>
                  </div>
                </div>
              </div>
            </div>


          </div>
        </div>
        <div class="col-xl-5 col-sm-6 col-12">
          <div class="row my-2">
            <div class="col-xl-12 col-sm-6 col-12">
              <div class="card card-2 graphCard">
                <div class="card-content">
                  <div class="card-body">
                    <div class="d-flex align-items-center position-relative mb-1">
                      <b-icon icon="arrows-expand" class="GraphIcon" font-scale="1"></b-icon>
                      <p class="GraphLabel">Top 5 sales by hotels</p>
                    </div>
                    <!-- <ApexChart type="bar" height="300px" :options="chartOptionTwo" :series="chartSeriesTwo"></ApexChart> -->
                    <ApexChart v-if="hotelDatachart.Series[0].data.length > 0" type="bar" height="300px"
                      :options="hotelDatachart.Options" :series="hotelDatachart.Series"></ApexChart>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-xl-12 col-sm-6 col-12">
              <div class="card card-1 graphCard">
                <div class="card-content">
                  <div class="card-body">
                    <div class="d-flex align-items-center position-relative mb-1">
                      <b-icon icon="arrows-expand" class="GraphIcon" font-scale="1"></b-icon>
                      <p class="GraphLabel">Performance over time</p>
                    </div>
                    <ApexChart type="bar" height="300px" :options="ComparissonchartOptions" :series="ComparissonchartSeries">
                    </ApexChart>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-xl-12 col-sm-6 col-12">
              <div class="card card-1 graphCard">
                <div class="card-content">
                  <div class="card-body">
                    <div class="d-flex align-items-center position-relative mb-1">
                      <b-icon icon="arrows-expand" class="GraphIcon" font-scale="1"></b-icon>
                      <p class="GraphLabel">Distribution across hotel star ratings</p>
                    </div>
                    <!-- <ApexChart type="bar" height="300px" :options="FutureCheckInchartOptions"
                      :series="FutureCheckInchartSeries"></ApexChart> -->
                      
                      
                      <ApexChart v-if="HotelRatingchart.Series[0].data.length > 0" type="bar" height="300px" :options="HotelRatingchart.Options" 
                    :series="HotelRatingchart.Series"></ApexChart>
                  </div>
                </div>
              </div>
            </div>    

          </div>
        </div>
      </div>
    </section>



    <!-- ---------------------------------------------------------------------------------------------------------------------- -->

  </div>
</template>


<script>

import {
  BButton, BRow, BCol, BCard, BCollapse, VBToggle, BIcon, BFormGroup, BFormSelect
} from 'bootstrap-vue';
import axios from 'axios'
import datapie from './destinationfilter10006.json'
import corridorpie from './corridor10006.json'
import paxBar from './pax10006.json'
import checkins from './checkins.json'
import hotelrating from './hotelrating.json'
import hoteldata from './hoteldata.json'
import ref from 'vue'
export default {
  components: {
    // HelloWorld
    BButton, BRow, BCol, BCard, BCollapse, BIcon, BFormGroup, BFormSelect

  },
  directives: {
    'b-toggle': VBToggle,
  },
  data() {
    return {
      selected1: null,
      options1: [
        { value: null, text: 'Please select an option' },
        { value: 'a', text: 'Nirvana Travel & Tourism LLC' },
      ],
      selected2: null,
      options2: [
        { value: null, text: 'Please select an option' },
        { value: 'a', text: 'WITHIN EARTH' },
      ],
      selected3: null,
      options3: [
        { value: null, text: 'Please select an option' },
        { value: 'a', text: 'Nirvana Travel & Tourism LLC' },
      ],
      info: null,
      ComparissonchartSeries: [{
        name: 'Net Profit',
        data: [44, 55, 57, 56, 61, 58, 63, 60, 66, 44, 55, 57, 56, 61, 58, 63, 60, 66]
      }, {
        name: 'Revenue',
        data: [76, 85, 101, 98, 87, 105, 91, 114, 94, 76, 85, 101, 98, 87, 105, 91, 114, 94]
      }, {
        name: 'Free Cash Flow',
        data: [35, 41, 36, 26, 45, 48, 52, 53, 41, 35, 41, 36, 26, 45, 48, 52, 53, 41]
      }],
      ComparissonchartOptions: {
        chart: {
          type: 'bar',
          height: 350
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '55%',
            endingShape: 'rounded'
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          show: true,
          width: 2,
          colors: ['transparent']
        },
        xaxis: {
          categories: [],
        },
        colors: ['#0097da', '#4d5ba4', '#ff8000', '#2db8c7', '#e14d4d', '#ffd700'],
        yaxis: {
          title: {
            text: '$ (thousands)'
          }
        },
        fill: {
          opacity: 1
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return "$ " + val + " thousands"
            }
          }
        }
      },
      piechartSeries: [],
      piechartOptions: {
        labels: [],
        colors: ['#ffd700', '#4d5ba4', '#ff7f0e', '#6b6ecf', '#8ca252', '#bd9e39', '#ad494a', '#9c9ede', '#74c476', '#ed655a', '#8c6d31', '#9e9ac8','#2ca02c', '#d62728', '#9467bd', '#8c564b', '#e377c2', '#7f7f7f', '#bcbd22', '#17becf', '#393b79', ],
        chart: {
          width: 380,
          type: 'donut'
        },
        legend: {
          position: 'bottom'
        }
      },
      corridorpiechartSeries: [],
      corridorpiechartOptions: {
        labels: [],
        colors: ['#ffd700', '#1f77b4', '#ff7f0e', '#2ca02c', '#d62728', '#9467bd', '#8c564b', '#e377c2', '#7f7f7f', '#bcbd22', '#17becf', '#393b79', '#6b6ecf', '#8ca252', '#bd9e39', '#ad494a', '#9c9ede', '#74c476', '#ed655a', '#8c6d31', '#9e9ac8',],
        chart: {
          width: 380,
          type: 'donut'
        },
        legend: {
          position: 'bottom'
        }
      },




      chartSeriesOne: [{
        name: 'Net Profit',
        data: [44, 55, 57, 56]
      }],
      chartOptionOne: {
        chart: {
          type: 'bar',
          height: 350
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '55%',
            endingShape: 'rounded'
          },
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          show: true,
          width: 2,
          colors: ['transparent']
        },
        xaxis: {
          categories: ['Feb', 'Jul', 'Sep', 'Oct'],
        },
        yaxis: {
          title: {
            text: '$ (hundreds)'
          }
        },
        colors: ['#0097da', '#4d5ba4', '#ff8000', '#2db8c7', '#e14d4d', '#ffd700'],
        fill: {
          opacity: 1
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return "$ " + val + " hundreds"
            }
          }
        }
      },

      chartSeriesTwo: [{
        name: 'Net Profit',
        data: [33, 55, 25, 30]
      }],
      chartOptionTwo: {
        chart: {
          type: 'bar',
          height: 350
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '55%',
            endingShape: 'rounded'
          },
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          show: true,
          width: 2,
          colors: ['transparent']
        },
        xaxis: {
          categories: ['Feb', 'Jul', 'Sep', 'Oct'],
        },
        yaxis: {
          title: {
            text: '$ (hundreds)'
          }
        },
        colors: ['#4d5ba4', '#ff8000', '#2db8c7', '#e14d4d', '#ffd700', '#0097da'],
        fill: {
          opacity: 1
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return "$ " + val + " hundreds"
            }
          }
        }
      },



      paxchart: {
        Series: [
          {
            name: 'Distribution',
            data: []
          }
        ],
        Options: {
          chart: {
            type: 'bar',
            height: 350
          },

          xaxis: {
            categories: [],
          },
          yaxis: {
            title: {
              text: ''
            }
          },
          colors: ['#0097da', '#4d5ba4', '#ff8000', '#2db8c7', '#e14d4d', '#ffd700'],
          tooltip: {
            y: {
              formatter: function (val) {
                return val
              }
            }
          }
        },
      },


      FutureCheckInchart: {
        Series: [
          {
            name: 'Distribution',
            data: []
          }
        ],
        Options: {
          chart: {
            type: 'bar',
            height: 350
          },

          xaxis: {
            categories: [],
          },
          yaxis: {
            title: {
              text: ''
            }
          },
          colors: ['#ff8000', '#2db8c7', '#e14d4d', '#ffd700'],
          tooltip: {
            y: {
              formatter: function (val) {
                return val
              }
            }
          }
        },
      },

      HotelRatingchart: {
        Series: [
          {
            name: 'Distribution',
            data: []
          }
        ],
        Options: {
          chart: {
            type: 'bar',
            height: 350
          },

          xaxis: {
            categories: [],
          },
          yaxis: {
            title: {
              text: ''
            }
          },
          colors: ['#4d5ba4', '#ff8000', '#2db8c7', '#e14d4d', '#ffd700'],
          tooltip: {
            y: {
              formatter: function (val) {
                return val
              }
            }
          }
        },
      },

      hotelDatachart: {
        Series: [
          {
            name: 'Sales',
            data: []
          }
        ],
        Options: {
          chart: {
            type: 'bar',
            height: 350
          },

          xaxis: {
            categories: [],
          },
          yaxis: {
            title: {
              text: ''
            }
          },
          colors: ['#0097da', '#4d5ba4', '#ff8000', '#2db8c7', '#e14d4d', '#ffd700'],
          tooltip: {
            y: {
              formatter: function (val) {
                return val + " AED"
              }
            }
          }
        },
      },








      TurkeySeries: [{
        name: "Trends",
        data: [1, 3, 1, 1, 3, 1]
      }],
      TurkeyOptions: {
        chart: {
          height: 350,
          type: 'line',
          zoom: {
            enabled: false
          }
        },
        markers: {
          size: 1
        },
        dataLabels: {
          enabled: false
        },
        title: {
          text: 'Destination Trends by Month',
          align: 'left'
        },
        yaxis: {
          reversed: true,
          min: 0,
          max: 5,
          logBase: 10,
          tickAmount: 5,
        },
        grid: {
          row: {
            colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
            opacity: 0.5
          },
        },
        xaxis: {
          categories: ['Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug'],
        }
      },


      FranceSeries: [{
        name: 'Net Profit',
        data: [44, 55, 57, 56, 61, 58]
      }],
      FranceOptions: {
        chart: {
          height: 350,
          type: 'line',
          zoom: {
            enabled: false
          }
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'straight'
        },
        title: {
          text: 'Product Trends by Month',
          align: 'left'
        },
        grid: {
          row: {
            colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
            opacity: 0.5
          },
        },
        xaxis: {
          categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep'],
        }
      },


      topFiveDestinations: [
        { rank: 1, march_2023: 'Saudi Arabia', april_2023: 'Malaysia', may_2023: 'Saudi Arabia', june_2023: 'Saudi Arabia', july_2023: 'UAE', august_2023: 'Saudi Arabia' },
        { rank: 2, march_2023: 'Egypt', april_2023: 'UAE', may_2023: 'Tunisia', june_2023: 'France', july_2023: 'Malaysia', august_2023: 'UAE' },
        { rank: 3, march_2023: 'Malaysia', april_2023: 'Saudi Arabia', may_2023: 'Egypt', june_2023: 'Egypt', july_2023: 'Saudi Arabia', august_2023: 'Thailand' },
        { rank: 4, march_2023: 'Thailand', april_2023: 'France', may_2023: 'UAE', june_2023: 'Tunisia', july_2023: 'Thailand', august_2023: 'Malaysia' },
        { rank: 5, march_2023: 'UAE', april_2023: 'Thailand', may_2023: 'Malaysia', june_2023: 'Malaysia', july_2023: 'Egypt', august_2023: 'Egypt' },
      ],
      topTurkeyDestinations: [
        { check_in_month: 'March 2023', rank: 1 },
        { check_in_month: 'April 2023', rank: 3 },
        { check_in_month: 'May 2023', rank: 1 },
        { check_in_month: 'June 2023', rank: 1 },
        { check_in_month: 'July 2023', rank: 3 },
        { check_in_month: 'August 2023', rank: 1 },
      ],
      topFranceDestinations: [
        { check_in_month: 'March 2023', rank: 4 },
        { check_in_month: 'April 2023', rank: 2 },
        { check_in_month: 'May 2023', rank: 3 },
        { check_in_month: 'June 2023', rank: 6 },
        { check_in_month: 'July 2023', rank: 1 },
        { check_in_month: 'August 2023', rank: 1 },
      ],
      baseEffect: true,
      rateList: [],
      datapielabel: [],
      datapieseries: [],
    }
  },
  methods: {
    prevSlide: function () {

      if (this.block == 1) {
        this.block = 1
        // document.getElementById("prevBTN").disabled = true;
      }
      else {
        this.block--;
        console.log("hello prev", this.block);
      }


    },
    nextSlide: function () {
      // this.block = 3
      // console.log("hello next", this.block);
      if (this.block == 3) {
        this.block = 3
        // document.getElementById("nextBTN").disabled = true;
      }
      else {
        this.block++;
        console.log("hello prev", this.block);
      }
    },
    getRates() {
      axios
        .get(
          'https://api.coindesk.com/v1/bpi/currentprice.json'
        )
        .then((response) => {
          let rateValue = response.data.bpi;
          for (let key in rateValue) {
            this.rateList.push(rateValue[key]);
          }
          // console.log(rateValue)
        });
    },
    piedataprocess() {
      // console.log("value", datapie.destination_country.length)
      for (let index = 0; index < datapie.destination_country.length; index++) {
        // console.log(datapie.destination_country[index]);
        // console.log('----')
        if (datapie.destination_country[index] == null) {
          this.piechartOptions.labels.push('Others');
        } else {
          this.piechartOptions.labels.push(datapie.destination_country[index]);
        }
        this.piechartSeries.push(datapie.total_sales[index]);

      }
    },
    corridorpiedataprocess() {
      // console.log("value", corridorpie.corridor.length)
      for (let index = 0; index < corridorpie.corridor.length; index++) {
        // console.log(corridorpie.corridor[index]);
        // console.log('----')
        if (corridorpie.corridor[index] == null) {
          this.corridorpiechartOptions.labels.push('Others');
        } else {
          this.corridorpiechartOptions.labels.push(corridorpie.corridor[index]);
        }
        this.corridorpiechartSeries.push(corridorpie.total_sales[index]);

      }
    },
    FutureCheckInBarprocess() {
      console.log("checkins", checkins.keys.length)
      this.FutureCheckInchart.Series[0].data = []
      for (let index = 0; index < checkins.keys.length; index++) {
        console.log("index ", index, checkins.values[index], "demo2", checkins.keys[index]);
        console.log('----')

        // this.FutureCheckInchart.Series[0].data.push(paxBar.values[index]);
        this.FutureCheckInchart.Series[0].data.push(checkins.values[index]);
        //  console.log("series",this.BasicTestSeries.data);

        this.FutureCheckInchart.Options.xaxis.categories.push(checkins.keys[index]);
        // console.log("options",this.BasicTestOptions.xaxis.categories);
        console.log("this.FutureCheckInchart", this.FutureCheckInchart)
      }


    },


    HotelRatingBarprocess() {
      console.log("hotelrating", hotelrating.ratings.rating.length)
      this.HotelRatingchart.Series[0].data = []
      for (let index = 0; index < hotelrating.ratings.rating.length; index++) {
        console.log("index ", index, hotelrating.total_rating.total_rating[index], "demo2", hotelrating.ratings.rating[index]);
        console.log('----')

        // this.HotelRatingchart.Series[0].data.push(paxBar.values[index]);
        this.HotelRatingchart.Series[0].data.push(hotelrating.total_rating.total_rating[index]);
        //  console.log("series",this.BasicTestSeries.data);

        this.HotelRatingchart.Options.xaxis.categories.push(hotelrating.ratings.rating[index]);
        // console.log("options",this.BasicTestOptions.xaxis.categories);
        console.log("this.HotelRatingchart", this.HotelRatingchart)
      }


    },



    paxBarprocess() {
      console.log("paxBar", paxBar.keys.length)
      this.paxchart.Series[0].data = []
      for (let index = 0; index < paxBar.keys.length; index++) {
        console.log("index ", index, paxBar.values[index], "demo2", paxBar.keys[index]);
        console.log('----')

        // this.FutureCheckInchart.Series[0].data.push(paxBar.values[index]);
        this.paxchart.Series[0].data.push(paxBar.values[index]);
        //  console.log("series",this.BasicTestSeries.data);

        this.paxchart.Options.xaxis.categories.push(paxBar.keys[index]);
        // console.log("options",this.BasicTestOptions.xaxis.categories);
        console.log("this.paxchart", this.paxchart)
      }
    },


    hotelDataBarprocess() {
      console.log("paxBar", hoteldata.hotel_names.length)
      this.hotelDatachart.Series[0].data = []
      for (let index = 0; index < hoteldata.hotel_names.length; index++) {
        console.log("index ", index, hoteldata.total_sales[index], "demo2", hoteldata.hotel_names[index]);
        console.log('----')

        // this.FutureCheckInchart.Series[0].data.push(hoteldata.total_sales[index]);
        this.hotelDatachart.Series[0].data.push(hoteldata.total_sales[index]);
        //  console.log("series",this.BasicTestSeries.data);

        this.hotelDatachart.Options.xaxis.categories.push(hoteldata.hotel_names[index]);
        // console.log("options",this.BasicTestOptions.xaxis.categories);
        console.log("this.hotelDatachart", this.hotelDatachart)
      }
    },

  },
  mounted() {
    console.log(datapie);
    this.piedataprocess();
    this.corridorpiedataprocess();
    this.paxBarprocess();
    this.FutureCheckInBarprocess();
    this.HotelRatingBarprocess();
    this.hotelDataBarprocess();
    // axios
    //   .get('https://api.coindesk.com/v1/bpi/currentprice.json')
    //   .then(response => (this.info = response.data.bpi))
  },
  setup() {
    // console.log("element");
    const piechartone = ref([]);

    return {
      piechartone,

    }
  }
}
</script>